import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import TwitterIcon from "../../../assets/images/icons/DTE-Twitter.png";
import FacebookIcon from "../../../assets/images/icons/DTE-Facebook.png";
import YouTubeIcon from "../../../assets/images/icons/DTE-YouTube.png";
import LinkedInIcon from "../../../assets/images/icons/DTE-LinkedIn.png";

import { Navbar, Nav, NavItem } from "reactstrap";
import { useIntl } from "react-intl";
import GaTracker from "../../../../utils/GaTracker/GaTracker";
import DisclaimerComponent from "@zappy-ride/disclaimer-footer-component";
const Footer = () => {
  const intl = useIntl();

  const handleClickWebsite = (e) => {
    GaTracker.trackEvent({
      category: "Header",
      action: "Clicked Header Website Link",
      label: "Link Click",
    });
  };

  const socialIcons = (
    <div className="social-icons mb-2">
      <a
        onClick={(e) => handleClickWebsite(e)}
        href="https://twitter.com/dte_energy"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="Twitter Icon" src={TwitterIcon} />
      </a>
      <a
        onClick={(e) => handleClickWebsite(e)}
        href="https://www.facebook.com/dteenergy"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="Facebook Icon" src={FacebookIcon} />
      </a>
      <a
        onClick={(e) => handleClickWebsite(e)}
        href="https://www.linkedin.com/company/dte-energy"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="LinkedIn Icon" src={LinkedInIcon} />
      </a>
      <a
        onClick={(e) => handleClickWebsite(e)}
        href="https://www.youtube.com/user/DTEEnergyCompany"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="YouTube Icon" src={YouTubeIcon} />
      </a>
    </div>
  );

  const subFooter = (
    <Navbar expand="lg">
      <Nav className="nav-fill w-100" navbar>
        <NavItem
          onClick={(e) => handleClickWebsite(e)}
          className="footer-border pad-right"
        >
          <a href="https://www.dteenergy.com/us/en/residential/service-request/pev/plug-in-electric-vehicles-pev.html/?utm_campaign=ev_homepage_cta&utm_source=zappy&utm_medium=affiliate_ev_site&utm_content=">
            EV Resources
          </a>
        </NavItem>
        <NavItem
          onClick={(e) => handleClickWebsite(e)}
          className="footer-border pad-right pad-left"
        >
          <a href="https://www.dteenergy.com/us/en/residential/service-request/pev/home-ev-charger-rebate.html">
            Home EV Charger Rebate
          </a>
        </NavItem>
        <NavItem
          onClick={(e) => handleClickWebsite(e)}
          className="footer-border pad-right pad-left"
        >
          <a href="https://newlook.dteenergy.com/wps/wcm/connect/dte-web/home/service-request/residential/electric/pev/pev-res-rate-plans">
            Electric Pricing
          </a>
        </NavItem>
        <NavItem
          onClick={(e) => handleClickWebsite(e)}
          className="footer-border pad-right pad-left"
        >
          <a href="https://newlook.dteenergy.com/wps/wcm/connect/dte-web/home/service-request/residential/electric/pev/charger-installation">
            Install a Charger
          </a>
        </NavItem>
        <NavItem
          onClick={(e) => handleClickWebsite(e)}
          className="footer-border pad-right pad-left"
        >
          <a href="https://newlook.dteenergy.com/wps/wcm/connect/dte-web/home/service-request/residential/electric/pev/pev-res-cost-savings">
            Cost Savings & Benefits
          </a>
        </NavItem>
        <NavItem onClick={(e) => handleClickWebsite(e)} className="pad-left">
          <a href="https://newlook.dteenergy.com/wps/wcm/connect/dte-web/home/service-request/residential/electric/pev/ev-help">
            EV Help
          </a>
        </NavItem>
      </Nav>
    </Navbar>
  );

  return (
    <div className="Footer">
      <div className="container pt-2 pb-4">
        <div className="social-media-row">{socialIcons}</div>
        <div className="row zr-row">
          {process.env.REACT_APP_PAGES_VEHICLES_ENABLED ? (
            <>
              <Link
                onClick={(e) => handleClickWebsite(e)}
                to="/vehicles"
                className="footer-border pad-right"
              >
                {process.env.REACT_APP_PAGES_VEHICLES_TITLE}
              </Link>
            </>
          ) : null}
          <Link
            onClick={(e) => handleClickWebsite(e)}
            to="/comparison-graph"
            className="footer-border pad-right pad-left"
          >
            {intl.formatMessage({
              id: "vehicle.costVsRange",
              defaultMessage: "Cost vs. Range",
            })}
          </Link>
          {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED ? (
            <>
              <Link onClick={(e) => handleClickWebsite(e)} to="/incentives">
                {process.env.REACT_APP_PAGES_INCENTIVES_TITLE}
              </Link>
              <br />
            </>
          ) : null}
          {process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED ? (
            <>
              <Link
                onClick={(e) => handleClickWebsite(e)}
                to={process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK}
                className="footer-border pad-right pad-left"
              >
                {process.env.REACT_APP_PAGES_CUSTOM_1_NAVNAME}
              </Link>
            </>
          ) : null}
          {process.env.REACT_APP_PAGES_CHARGING_ENABLED ? (
            <>
              <Link
                onClick={(e) => handleClickWebsite(e)}
                to="/charging-stations"
                className="pad-left"
              >
                {process.env.REACT_APP_PAGES_CHARGING_TITLE}
              </Link>
            </>
          ) : null}
          {process.env.REACT_APP_PAGES_DEALERS_ENABLED ? (
            <>
              <Link onClick={(e) => handleClickWebsite(e)} to="/dealers">
                {process.env.REACT_APP_PAGES_DEALERS_TITLE}
              </Link>
              <br />
            </>
          ) : null}
          {process.env.REACT_APP_PAGES_FAQ_ENABLED ? (
            <>
              <Link onClick={(e) => handleClickWebsite(e)} to="/faq">
                {process.env.REACT_APP_PAGES_FAQ_TITLE}
              </Link>
              <br />
            </>
          ) : null}
        </div>
        <div className="row dte-row">{subFooter}</div>
      </div>
      <div className="container-fluid">
        <div className="container pb-4">
          <p
            style={{
             marginLeft:"210px",
              marginBottom: 0,
              color: "#fff",
              fontSize: "11px",
            }}
          >
            <span>&copy;</span>
            {new Date().getFullYear().toString()}{" "}
            {process.env.REACT_APP_FULL_COMPANY_NAME}. All Rights Reserved.
          </p>
          <span className="disclaimer_container" >
                  <DisclaimerComponent clientName="zappynobackground"
                    imageStyle={{marginLeft:"425px", marginTop:"-54px"}}
                    wrapperStyle={{position:"0px"}}
                    textStyle={{textAlign:"center", fontSize:"11px", fontFamily:"Open Sans, Helvetica, sans-serif"}}
                  />

                  {/* <DisclaimerComponent clientName="zappynobackground" textStyle={{fontFamily:"TT Norms Pro", fontSize:"14px"}} imageStyle={{marginLeft:"385px"}} wrapperStyle={{ 
    marginTop: '-35px'
  }}/> */}

                </span>
          {/* <p style={{
            textAlign: "center",
            marginBottom: 0,
            color: "#fff",
            fontSize: "11px",
          }}>
            <span style={{ fontFamily: "Arial, Helvetica" }}>
              <a style={{ fontFamily: "Arial, Helvetica", fontSize: "11px" }} rel="noopener noreferrer" target="_blank" href="https://zappyride.com/legal-notices">Legal notices.</a>
            </span>
          </p>
          <p
            style={{
              textAlign: "center",
              marginBottom: 0,
              color: "#fff",
              fontSize: "11px",
            }}
          >
            Powered by J.D. Power ZappyRide
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
